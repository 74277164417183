/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{ cvCardTitle }}
            </h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">
              {{ cvCardSubHeader }}
            </p>
            <ValidationObserver ref="carrForm">
              <form action="#">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_field">
                      {{cvCarrFieldLabel}}</label>
                      <ValidationProvider
                        name="Career Field Name"
                        rules="required"
                        v-slot="{ errors }">
                        <multiselect
                          v-model="vmCareerExamFormData.carr_field"
                          :options="cvCareerFieldOptions"
                          :placeholder="cvSelectBoxText + cvCarrFieldLabel"
                          label="carr_field"
                          track-by="carr_id">
                      </multiselect>
                      <span class="text-danger"> {{ errors[0] }} </span>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_exam_name">
                      {{cvCarrExamNameLabel}}</label>
                      <ValidationProvider
                          name="Career Exam Name"
                          rules="required"
                          v-slot="{ errors }">
                      <input
                        v-model="vmCareerExamFormData.carr_exam_name"
                        type="text"
                        class="form-control"
                        required/>
                      <span class="text-danger" mt-1> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_exam_desc">
                      {{cvCarrExamDescLabel}}</label>
                    <ValidationProvider
                        name="Career Description"
                        rules="required"
                        v-slot="{ errors }">
                        <input
                          v-model="vmCareerExamFormData.carr_exam_desc"
                          type="text"
                          class="form-control"
                          required/>
                      <span class="text-danger" mt-1> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_exam_for">
                      {{cvCarrExamForLabel}}</label>
                      <ValidationProvider
                        name="Career Exam for"
                        rules="required"
                        v-slot="{ errors }">
                        <input
                          v-model="vmCareerExamFormData.carr_exam_for"
                          type="text"
                          class="form-control"
                          required/>
                      <span class="text-danger" mt-1> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_exam_weblink">
                      {{cvCarrExamWeblinkLabel}}</label>
                    <ValidationProvider
                      name="Career Exam Weblink"
                      rules="required"
                      v-slot="{ errors }">
                      <input
                        v-model="vmCareerExamFormData.carr_exam_weblink"
                        type="text"
                        class="form-control"
                        required/>
                      <span class="text-danger" mt-1> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_exam_country">
                      {{cvCarrExamCountryLabel}}</label>
                      <ValidationProvider
                      name="Career Exam Country"
                      rules="required"
                      v-slot="{ errors }">
                    <input
                      v-model="vmCareerExamFormData.carr_exam_country"
                      type="text"
                      class="form-control"
                      required/>
                    <span class="text-danger" mt-1> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row spinner_add">
                  <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning">
                    </b-spinner>
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="careerExamAdd()">
                    {{ cvSubmitBtn }}
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import Multiselect from "vue-multiselect"
import { CareerExams } from "../../../FackApi/api/careerExam"
import CareerUtility from "./CareerUtility"
import ApiResponse from "../../../Utils/apiResponse.js"
import { ValidationProvider } from "vee-validate"

export default {
  name: "CareerExamAdd",
  components: {
    Multiselect,
    ValidationProvider
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvSelectBoxText: "Select",
      cvCardTitle: "Add careerExam",
      cvCardSubHeader: "Add careerExam",
      cvSubmitBtn: "Add",
      cvCarrFieldLabel: "career",
      cvCarrExamNameLabel: "carr exam name",
      cvCareerFieldOptions: [],
      cvCarrExamDescLabel: "carr exam desc",
      cvCarrExamForLabel: "carr exam for",
      cvCarrExamWeblinkLabel: "carr exam weblink",
      cvCarrExamCountryLabel: "carr exam country",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "CareerExam",
      vmCareerExamFormData: Object.assign({}, this.initFormData()),
      vmCareerExamDesc: null,
      vmCareerExamType: null
    }
  },
  async mounted () {
    this.cvCareerFieldOptions = await this.loadRequiredData()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "carr_field": "",
        "carr_exam_name": "",
        "carr_exam_desc": "",
        "carr_exam_for": "",
        "carr_exam_weblink": "",
        "carr_exam_country": ""
      }
    },
    loadRequiredData () {
      return CareerUtility.careerFieldList(this)
    },

    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCareerExamFormData) {
          if (!this.vmCareerExamFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * careerExamAdd
     */
    async careerExamAdd () {
      let valid = await this.$refs.carrForm.validate()
      if (!valid) {
        return
      }
      try {
        this.cvLoadingStatus = true
        this.vmCareerExamFormData.carr_field = this.vmCareerExamFormData.carr_field.carr_field
        let careerExamAddResp = await CareerExams.careerExamAdd(this, this.vmCareerExamFormData)
        await ApiResponse.responseMessageDisplay(this, careerExamAddResp)
        if (careerExamAddResp && !careerExamAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseCareerExamAddModal", careerExamAddResp.resp_data)
        }
        this.vmCareerExamFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at careerExamAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
